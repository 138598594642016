<template>
  <v-container id="judges-awards-mapping" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn color="success" class="mr-4" @click="backPage">
                  Back
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="ProgessStart == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-progress-circular
                  :rotate="180"
                  :size="100"
                  :width="15"
                  :value="uploadPercentage"
                  color="#00a4ef"
                >
                  {{ uploadPercentage }}
                </v-progress-circular>
                <h3 class="red--text">We are fetching details, please wait.</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row wrap>
              <v-col cols="12" md="3">
                <v-autocomplete
                  @change="getTableRecords"
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  v-model="JciYearCode"
                  label="Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="4">
                <h5 class="blue--text text--lighten">LOM Name</h5>
                <p>{{ rows.LomName }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <h5 class="blue--text text--lighten">Charter Date</h5>
                <p>{{ rows.CharterDateTxt }}</p>
              </v-col>
            </v-row>
            <br />
          </v-container>

          <v-card>
            <v-toolbar flat color="deep-purple accent-4" dark>
              <v-toolbar-title> {{ rows.LomName }} </v-toolbar-title>
            </v-toolbar>
            <v-tabs
              v-model="tab"
              background-color="deep-purple accent-4"
              centered
              dark
              icons-and-text
              show-arrows
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                Governing Board Members
                <v-icon>mdi-numeric-1-box-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-2">
                Members
                <v-icon>mdi-numeric-2-box-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-3">
                Senior Members
                <v-icon>mdi-numeric-3-box-outline</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <governing-board-members
                      :records="BoardMembers"
                    ></governing-board-members>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>
                    <!-- <lom-members :records="AssociateMembers"></lom-members> -->
                    <members-display :records="LomMembers"></members-display>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card flat>
                  <v-card-text>
                    <!-- <lom-members :records="SustainingMembers"></lom-members> -->
                    <senior-members-display
                      :records="SeniorMembers"
                    ></senior-members-display>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import LomDescription from "./LomDescription.vue";
import LomMembers from "./LomMembers.vue";
import GoverningBoardMembers from "@/view/pages/members/GoverningBoardMembers.vue";
import MembersDisplay from "@/view/pages/members/MembersDisplay.vue";
import SeniorMembersDisplay from "@/view/pages/members/SeniorMembersDisplay.vue";

export default {
  mixins: [common],
  components: {
    LomDescription,
    LomMembers,
    GoverningBoardMembers,
    MembersDisplay,
    SeniorMembersDisplay,
  },
  data: () => ({
    ProgessStart: 0,
    uploadPercentage: 0,

    SuccessMessageTxtFlag: 0,
    SuccessMessageTxt: "",

    ErrorMessageTxtFlag: 0,
    ErrorMessageTxt: "",

    totalActiveAwards: 0,
    totalPendingAwards: 0,

    ResultFlag: 0,

    tab: null,
    LomId: "",
    LomDetails: {},
    BoardMembers: [],
    LomMembers: [],
    SeniorMembers: [],

    rows: [],

    JciYearCodeRules: [(v) => !!v || "Year is required"],
    JciYearCodeOptions: [],

    PageInfo: {},
    PageTitle: "",
    PageDescription: "",
    JciYearCode: "",
    YearName: "",
    NextYearCode: "",
    NextYearName: "",
  }),
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        // this.LomCode = LomId;
        console.log(this.$route.query.id);
        this.LomId = this.$route.query.id;

        var ZoneCode = this.$session.get("LomListZoneCode");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = Object.keys(rows).length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        /*
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));
        */
        this.LomDetails = rows;
        this.BoardMembers = rows.BoardMembers;
        this.LomMembers = rows.LomMembers;
        this.SeniorMembers = rows.SeniorMembers;
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getJciYearCodeOptions();
      this.getTableRecords();
    },
    backPage() {
      this.$router.go(-1);
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/with-members";
      var upload = {
        UserInterface: 1,
        LomCode: this.LomId,
        YearCode: this.JciYearCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.SuccessMessageTxtFlag = 1;
            thisIns.SuccessMessageTxt = output;
            thisIns.rows = records;
          } else {
            thisIns.ErrorMessageTxtFlag = 1;
            thisIns.ErrorMessageTxt = output;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.ProgessStart = 0;
        });
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    this.refreshPageData();
    /*
    if (RoleId == 1 || RoleId == 2 || RoleId == 3) {
      this.refreshPageData();
    } else {
      this.$router.push("/access-denied");
    }
    */
  },
};
</script>

<style>
</style>
